<template>
  <div class="addMenu">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :getSubmitForm="getSubmitForm"
      @update="update"
    >
      <template #saveAfterSlot>
        <v-button text="取消" @click="$router.go(-1)"></v-button>
      </template>
      <template>
        <el-form-item
          label="模板归属"
          :rules="[
            { required: true, message: '请选择模板归属', trigger: 'blur' },
          ]"
          prop="domain"
        >
          <v-select
            :disabled="id !== undefined"
            :options="moduleListMapOps"
            placeholder="请选择"
            v-model="form.domain"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="菜单类型"
          :rules="[
            { required: true, message: '请选择菜单类型', trigger: 'blur' },
          ]"
          prop="category"
        >
          <v-select
            :disabled="id !== undefined"
            :options="menuCategoryOps"
            placeholder="请选择"
            v-model="form.category"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="父级菜单"
          :rules="[
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
          ]"
          prop="parentName"
        >
          <v-input
            isReadonly
            placeholder="请输入"
            v-model="form.parentName"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="菜单名称"
          :rules="[
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
          ]"
          prop="name"
        >
          <v-input placeholder="请输入" v-model="form.name"></v-input>
        </el-form-item>
        <el-form-item
          label="路由地址"
          :rules="[
            { required: true, message: '请输入路由地址', trigger: 'blur' },
          ]"
          prop="path"
        >
          <v-input placeholder="请输入" v-model="form.path"></v-input>
        </el-form-item>
        <el-form-item
          v-if="form.domain != 1"
          label="跳转地址"
          :rules="[
            { required: false, message: '请输入跳转地址', trigger: 'blur' },
          ]"
          prop="url"
        >
          <v-input placeholder="请输入" v-model="form.url"></v-input>
        </el-form-item>
        <el-form-item
          v-if="
            isEdit &&
            form.parentName == '顶级' &&
            (form.domain == 0 || form.domain == 2 || form.domain == 4 || form.domain == 6)
          "
          label="菜单icon"
          :rules="[
            { required: true, message: '请上传菜单icon', trigger: 'blur' },
          ]"
          prop="source"
        >
          <v-upload :limit="1" :imgUrls.sync="form.source" />
        </el-form-item>
        <el-form-item
          v-if="
            isEdit &&
            form.parentName == '顶级' &&
            (form.domain == 0 || form.domain == 2 || form.domain == 4 || form.domain == 6)
          "
          label="选中菜单icon"
          :rules="[
            { required: true, message: '请上传菜单icon', trigger: 'blur' },
          ]"
          prop="selectSource"
        >
          <v-upload :limit="1" :imgUrls.sync="form.selectSource" />
        </el-form-item>
        <el-form-item
          label="排序"
          :rules="[{ required: false, message: '请输入排序', trigger: 'blur' }]"
          prop="sort"
        >
          <v-input v-model="form.sort" type="number"></v-input>
        </el-form-item>
      </template>
      <template v-if="form.domain == 1">
        <el-form-item
          label="是否打开新页面"
          :rules="[
            { required: true, message: '请选择模板归属', trigger: 'blur' },
          ]"
          prop="isOpen"
        >
          <v-select
            :options="isOpenOps"
            placeholder="请选择"
            v-model="form.isOpen"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="是否隐藏"
          :rules="[
            { required: true, message: '请选择菜单类型', trigger: 'blur' },
          ]"
          prop="hidden"
        >
          <v-select
            :options="isHideMenuOps"
            placeholder="请选择"
            v-model="form.hidden"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="跳转第三方地址"
          :rules="[
            {
              required: false,
              message: '请输入跳转第三方地址',
              trigger: 'blur',
            },
          ]"
          prop="url"
        >
          <v-input
            placeholder="请输入跳转第三方地址"
            v-model="form.url"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="重定向地址"
          :rules="[
            { required: false, message: '请输入重定向地址', trigger: 'blur' },
          ]"
          prop="redirect"
        >
          <v-input
            placeholder="请输入重定向地址"
            v-model="form.redirect"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="激活地址"
          :rules="[
            { required: false, message: '请输入激活地址', trigger: 'blur' },
          ]"
          prop="currentPath"
        >
          <v-input
            placeholder="请输入激活地址"
            v-model="form.currentPath"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="备注地址"
          :rules="[
            { required: false, message: '请输入备注地址', trigger: 'blur' },
          ]"
          prop="remark"
        >
          <v-input placeholder="请输入备注地址" v-model="form.remark"></v-input>
        </el-form-item>
      </template>
    </form-panel>
  </div>
</template>

<script>
import {
  moduleListMapOps,
  menuCategoryOps,
  isHideMenuOps,
  isOpenOps,
} from "./map.js";
import { getMenuDetailUrl, postMenuSubmitUrl } from "./api.js";

export default {
  name: "addMenu",
  data() {
    return {
      domainName: "", //模块归属
      isEdit: true, //编辑or新增子菜单
      moduleListMapOps: moduleListMapOps(), //模块归属
      menuCategoryOps: menuCategoryOps(), //菜单类型
      isHideMenuOps: isHideMenuOps(), //是否隐藏
      isOpenOps: isOpenOps(), //是否打开新页面
      id: "",
      form: {
        id: "", // 数据唯一值
        url: "",
        tenantId: "",
        domain: 0, //归属模板
        category: "", //菜单类型
        parentName: "顶级",
        name: "", //菜单名称
        path: "", //路由地址
        sort: "",
        parentId: "",
        redirect: "", //跳转地址
        source: "", //菜单icon
        isOpen: "", //是否打开新页面
        hidden: "", //是否隐藏
        currentPath: "", //激活地址
        remark: "", //备注地址
        selectSource: "", //选中菜单
      },
      submitConfig: {
        queryUrl: getMenuDetailUrl,
        queryMethod: "get",
        submitUrl: postMenuSubmitUrl,
      },
    };
  },
  computed: {},

  mounted() {
    const { id, domainName, isEdit } = this.$route.query;
    this.id = id;
    if (isEdit != undefined) {
      this.isEdit = isEdit;
    }
    this.domainName = domainName;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    submitBefore() {
      if (!this.id) {
        this.form.parentId = "0";
      }
      this.form.tenantId = this.$store.state.app.userInfo.tenantId;
      return true;
    },
    getSubmitForm(form) {
      if (form.source && form.selectSource) {
        form.source = [form.source, form.selectSource].join('|');
      }
      return form;
    },
    update(data) {
      if (data.source) {
        const sourceList = data.source.split('|');
        data.source = sourceList[0];
        data.selectSource = sourceList[1] || data.source.replace(".png", "-light.png");
      }
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      // if (this.form.source) {
      //   this.form.selectSource = this.form.source.replace(".png", "-light.png");
      // }
      if (!this.isEdit) {
        this.form.parentId = this.form.id;
        this.form.id = "";
        this.form.parentName = this.form.name;
        this.form.name = "";
        this.form.url = "";
        this.form.path = "";
        this.form.source = "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addMenu {
  height: 100%;
}
</style>
